import "./style.scss";
import Vue from "vue";

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

//boostrap components
import { NavbarPlugin, SidebarPlugin, TabsPlugin } from "bootstrap-vue";
Vue.use(NavbarPlugin);
Vue.use(SidebarPlugin);
Vue.use(TabsPlugin);

import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);

import VueResource from "vue-resource";
Vue.use(VueResource);

Vue.config.devtools = false;

Vue.http.options.root = "/edition/wp-admin/admin-ajax.php";

import browserDetect from "./framework/browser";
browserDetect();

import A11yDialog from "a11y-dialog";

// load design system atoms, molecules, organisms and pages
let components = require.context("./", true, /^\.\/.*\.js/);
components.keys().forEach(components);

// start app
let bundle = new Vue({
  el: "#app",
  delimiters: ["[[", "]]"],
  data() {
    return {
      sticky: false,
      isLg: false,
      searchNav: "",
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><span class="number">' + (index + 1) + '</span><span class="progress"><span class="progress-bar"></span></span></span>';
          },
        },
        loop: true,
      },
      swiperOptionAutoplay: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><span class="number">' + (index + 1) + '</span><span class="progress"><span class="progress-bar"></span></span></span>';
          },
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
        },

      },
      swiperHome: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
        loop: true,
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
          checkInView: true,
        },
      },
      swiperBottomOption: {
        slidesPerView: 2,
        spaceBetween: 20,
        watchSlidesProgress: true,
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
          checkInView: true,
        },
        breakpoints: {
          1024: {
            spaceBetween: 5,
          },
        },
        loop: true,
      },
    };
  },
  methods: {
    catchScroll: function () {
      this.sticky = window.pageYOffset > 0;
    },
    catchResize: function (event) {
      this.isLg = window.innerWidth >= 992;
    },
    updatePagination() {
      this.swiperHome.pagination =
        this.$refs.swiperHome.swiper.activeIndex +
        1 +
        "/" +
        this.$refs.swiperHome.swiper.slides.length;
    },
  },
  mounted: function () {
    let body_classList = document.body.classList;
    body_classList.remove("loading");

    body_classList.add("loaded");

    this.sticky = window.pageYOffset > 140;
    this.isLg = window.innerWidth >= 992;

    if (this.$refs.swiperHome) {
      this.swiperHome.pagination =
        this.$refs.swiperHome.swiper.activeIndex +
        1 +
        "/" +
        this.$refs.swiperHome.swiper.slides.length;
    }

    function handleFormSubmit(event) {
      event.preventDefault();
      // Check if the visitor mail is in the local storage
      if (localStorage.getItem("email")) {
        var visitorMail = localStorage.getItem("email");
      } else {
        var visitorMail = document.querySelector("#modal form input").value;
      }
      // Send the mail to the server
      // Using fetch, send the data to the server
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic Y2tfOTI5M2FlNjY2NzllYmY5NTEwNjA4NjRjOTQzNDRlOGE4YjExNzU5ZTpjc18yYjBkOTRkYTM5NTc1YTM3OWQyNzZjOTI1ZWJkZjU4Zjg3ZjY1ZDVh"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        input_1: visitorMail,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://www.serieously.com/wp-json/gf/v2/forms/1/submissions",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    }
    // Select the target node
    const targetNode = document.querySelector("body");

    // Create a new MutationObserver instance
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const body = document.querySelector("body");
          if (window.innerWidth <= 768) {
          if (body.classList.contains("no-campaign-habilage-mobile")) {
            const existingDiv = document.querySelector(".check-habillage");
            existingDiv.classList.add("vsly-player");
            existingDiv.id = existingDiv.dataset.futureid;
            var script = document.createElement("script");
            script.id = "xieg6Sie";
            script.src = "https://cdn.viously.com/js/sdk/boot.js";
            script.async = true;
            document.body.appendChild(script);
            // Stop observing mutations once the class is detected
            observer.disconnect();
          }
        }
      }
    }
    });


    document.addEventListener('click', function(e) {
        if (e.target.closest('.obfuscate')) {
            const span = e.target.closest('.obfuscate');

            var futurhref = span.getAttribute('data-futurhref');
            var title = span.getAttribute('title');
            var ariaLabel = span.getAttribute('aria-label');
            var classes = span.classList;
            var icon = span.getAttribute('data-icon');
            var rel = span.getAttribute('rel');
            var decodedfuturhref = atob(futurhref);

            var a = document.createElement('a');
            a.setAttribute('href', decodedfuturhref);
            a.setAttribute('title', title);
            a.setAttribute('aria-label', ariaLabel);
            a.setAttribute('target', '_blank');
            a.setAttribute('rel', rel);
            a.setAttribute('data-icon', icon);

            for (var i = 0; i < classes.length; i++) {
                if (classes[i] !== 'obfuscate') {
                    a.classList.add(classes[i]);
                }
            }
            a.classList.add('obfuscated');

            a.innerHTML = span.innerHTML;

            span.parentNode.replaceChild(a, span);

            // Prevent the default click behavior
            e.preventDefault();

            // Optionally, trigger the click on the new anchor
            a.click();
        }
    });



    const viouslyDesktop = document.querySelector(".viously-desktop");
    if (viouslyDesktop) {
      if (window.innerWidth > 768) {
        const existingDiv = document.querySelector(".viously-desktop");
        existingDiv.classList.add("vsly-player");
        var script = document.createElement("script");
        script.id = "xieg6Sie";
        script.src = "https://cdn.viously.com/js/sdk/boot.js";
        script.async = true;
        document.body.appendChild(script);
      }
    }

    // Start observing the target node for attribute changes
    observer.observe(targetNode, { attributes: true });

    // If #tresor is on the page, when #tresor is clicked, open the dialog
    if (document.querySelector(".tresor")) {
      // For each tresor, open the dialog when clicked
      document.querySelectorAll(".tresor").forEach((tresor) => {
        // For each tresor, get the tresor-type using the data-tresor-type attribute
        tresor.addEventListener("click", (event) => {
          event.preventDefault();
          let tresorType = tresor.dataset.tresorType;
          // Check if this is the first time a tresor is found
          if (localStorage.getItem("tresorsFound") === null) {
            // Check if the tresor-type has already been found
            if (localStorage.getItem(tresorType) === null) {
              // If not, open the dialog
              let dialog = new A11yDialog(document.querySelector("#modal"));
              dialog.show();
              document
                .querySelector("#modal form")
                .addEventListener("submit", (event) => {
                  // When the input is submitted, add the value to the local storage
                  event.preventDefault();
                  let visitorMail =
                    document.querySelector("#modal form input").value;
                  localStorage.setItem("email", visitorMail);
                  // Launch the function that will send the data to the server
                  handleFormSubmit(event);

                  // Display the confirmation message
                  document.querySelector("#modal .modal-body").innerHTML =
                    "<p>Merci pour votre participation ! Vous pouvez fermer cette fenêtre. </p>";
                  // Close the dialog after 3 seconds
                });

              // When the dialog is closed, set the tresor-type as found
              dialog.on("hide", () => {
                //   localStorage.setItem(tresorType, true);
                localStorage.setItem("tresorsFound", 1);
              });
            }
          } else {
            // Check if email is in local storage
            if (localStorage.getItem("email")) {
              // If not, open the dialog
              let dialog = new A11yDialog(document.querySelector("#modal2"));
              dialog.show();
              handleFormSubmit(event);
            } else {
              // If not, open the dialog
              let dialog = new A11yDialog(document.querySelector("#modal"));
              dialog.show();
              document
                .querySelector("#modal form")
                .addEventListener("submit", (event) => {
                  // When the input is submitted, add the value to the local storage
                  event.preventDefault();
                  let visitorMail =
                    document.querySelector("#modal form input").value;
                  localStorage.setItem("email", visitorMail);
                  // Launch the function that will send the data to the server
                  handleFormSubmit(event);
                  // Display the confirmation message
                  document.querySelector("#modal .modal-body").innerHTML =
                    "<p>Merci pour votre participation ! Vous pouvez fermer cette fenêtre. </p>";
                  // Close the dialog after 3 seconds
                });

              // When the dialog is closed, set the tresor-type as found
              dialog.on("hide", () => {
                //   localStorage.setItem(tresorType, true);
                localStorage.setItem("tresorsFound", 1);
              });
              // When the dialog is closed, set the tresor-type as found
            }
          }
        });
      });
    }

  },
  created() {
    window.addEventListener("scroll", this.catchScroll);
    window.addEventListener("resize", this.catchResize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.catchScroll);
    window.removeEventListener("resize", this.catchResize);
  },
});


document.addEventListener('DOMContentLoaded', function() {
  // Sélectionner tous les éléments avec la classe 'slider-une'
  const sliders = document.querySelectorAll('.slider');

  // Parcourir tous les sliders une fois qu'ils sont sélectionnés
  sliders.forEach(function(slider) {
      // Récupérer les éléments nécessaires à l'intérieur du slider
      const galleryScroller = slider.querySelector('.scrolling-touch');
      const galleryNavItemNext = slider.querySelector('.next');
      const galleryNavItemPrev = slider.querySelector('.prev');
      const galleryItemSize = galleryScroller.querySelector('div').clientWidth;

      // Ajouter des écouteurs d'événements pour les boutons de navigation
      if (galleryNavItemNext && galleryNavItemPrev) {
          galleryNavItemNext.addEventListener('click', scrollToNextPage);
          galleryNavItemPrev.addEventListener('click', scrollToPrevPage);
      }

      // Fonction pour faire défiler vers la page suivante
      function scrollToNextPage() {
          galleryScroller.scrollBy(galleryItemSize, 0);
          galleryNavItemPrev.classList.remove('hidden');
      }

      // Fonction pour faire défiler vers la page précédente
      function scrollToPrevPage() {
          galleryScroller.scrollBy(-galleryItemSize, 0);
      }
  });
});
